// @flow

import { combineReducers } from 'redux';
import Layout from './layout/reducers';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import UsuarioReducer from './admin/usuario/reducers'
import EmpresaReducer from './admin/empresa/reducers'
import GrupoAcessoReducer from './admin/grupoacesso/reducers'
import PermissaoReducer from './admin/permissao/reducers'
import NotificacaoReducer from './notificacao/reducers'
import MotoristaReducer from './motorista/reducers'
import VeiculoReducer from './veiculo/reducers'
import TrocaVeiculoReducer from './solicitacao/trocaveiculo/reducers'
import AjusteKmReducer from './solicitacao/ajutekm/reducers'
import CorridaReducer from './ebooking/corrida/reducers'
import ReembolsoReducer from './solicitacao/reembolso/reducers'
import TipoDespesaReducer from './solicitacao/tipodespesa/reducers'
import AtualizacaoDocumentoReducer from './solicitacao/atualizacaodocumento/reducers'
import SolicitacaoReducer from './solicitacao/reducers'
import VeiculoMarcaReducer from './veiculomarca/reducers'
import VeiculoCorReducer from './veiculocor/reducers'
import DespesaAprovadaReducer from './backoffice/despesaaprovada/reducers'
import OperacaoReducer from './operacao/reducer'
import EstacaoReducer from './backoffice/estacao/reducers'
import EstadoReducer from './backoffice/estado/reducers'
import MalhaReducer from './backoffice/malha/reducers'
import CentroCustoReducer from './backoffice/centrocusto/reducers'
import AuditoriaReducer from './backoffice/auditoria/reducers'
import GrupoVeiculoReducer from './grupoveiculo/reducers'
import PassageiroReducer from './passageiro/reducers'
import AlterarEnderecoPassReducer from './backoffice/alterarenderecopass/reducers'
import TurnoReducer from './turno/reducers'
import LocalReducer from './local/reducers'
import TipoLocalReducer from './tipolocal/reducers'
import TaxiMachineReducer from './taximachime/reducers'
import SolicitacaoCorridaReducer from './ebooking/solicitacaocorrida/reducers'
import FaturamentoReducer from './backoffice/faturamento/reducers'
import DominioReducer from './admin/dominio/reducers'
import TarifaReducer from './ebooking/tarifa/reducers'
import ParametroReducer from './admin/parametro/reducers'
import LimitadorCorridaReducer from './ebooking/limitadorCorrida/reducers'
import DashboardReducer from './dashboard/reducers'
import ContratoReducer from './contrato/reducers'
import DisponibilidadeReducer from './disponibilidade/reducers'
import TipoOperacaoReducer from './backoffice/tipoOperacao/reducers'
import FeriadoReducer from './backoffice/feriado/reducers'
import MonitoramentoReducer from './monitoramento/reducers'
import NewSolicitacaoCorridaReducer from './ebooking/newSolicitacaoCorrida/reducers'

export default combineReducers({
    Auth,
    AppMenu,
    Layout,
    UsuarioReducer,
    EmpresaReducer,
    GrupoAcessoReducer,
    PermissaoReducer,
    NotificacaoReducer,
    MotoristaReducer,
    VeiculoReducer,
    TrocaVeiculoReducer,
    AjusteKmReducer,
    CorridaReducer,
    ReembolsoReducer,
    TipoDespesaReducer,
    AtualizacaoDocumentoReducer,
    SolicitacaoReducer,
    VeiculoMarcaReducer,
    VeiculoCorReducer,
    DespesaAprovadaReducer,
    OperacaoReducer,
    EstacaoReducer,
    EstadoReducer,
    MalhaReducer,
    GrupoVeiculoReducer,
    PassageiroReducer,
    AlterarEnderecoPassReducer,
    CentroCustoReducer,
    TurnoReducer,
    LocalReducer,
    TipoLocalReducer,
    TaxiMachineReducer,
    SolicitacaoCorridaReducer,
    AuditoriaReducer,
    FaturamentoReducer,
    DominioReducer,
    TarifaReducer,
    ParametroReducer,
    LimitadorCorridaReducer,
    DashboardReducer,
    ContratoReducer,
    DisponibilidadeReducer,
    TipoOperacaoReducer,
    FeriadoReducer,
    MonitoramentoReducer,
    NewSolicitacaoCorridaReducer
});