import React from 'react';
import { PrivateRoute } from './privateRoute';

const LocalCadastro = React.lazy(() => import('../../../pages/local/LocalCadastro'));
const LocalList = React.lazy(() => import('../../../pages/local/LocalList'));
const UsuarioList = React.lazy(() => import('../../../pages/Admin/usuario/UsuarioList'));
const UsuarioCadastro = React.lazy(() => import('../../../pages/Admin/usuario/UsuarioCadastro'));
const EmpresaList = React.lazy(() => import('../../../pages/Admin/empresa/EmpresaList'));
const EmpresaCadastro = React.lazy(() => import('../../../pages/Admin/empresa/EmpresaCadastro'));
const DominioList = React.lazy(() => import('../../../pages/Admin/dominio/DominioList'));
const DominioCadastro = React.lazy(() => import('../../../pages/Admin/dominio/DominioCadastro'));
const TarifaCadastro = React.lazy(() => import('../../../pages/ebooking/tarifa/TarifaCadastro'));
const TarifaList = React.lazy(() => import('../../../pages/ebooking/tarifa/TarifaList'));
const MotoristaCadastro = React.lazy(() => import('../../../pages/motorista/MotoristaCadastro'));
const MotoristaList = React.lazy(() => import('../../../pages/motorista/MotoristaList'));
const VeiculoCadastro = React.lazy(() => import('../../../pages/veiculo/VeiculoCadastro'));
const VeiculoList = React.lazy(() => import('../../../pages/veiculo/VeiculoList'));
const PassageiroCadastro = React.lazy(() => import('../../../pages/passageiro/PassageiroCadastro'));
const PassageiroList = React.lazy(() => import('../../../pages/passageiro/PassageiroList'));
const CentroCustoCadastro = React.lazy(() => import('../../../pages/backoffice/centrocusto/CentroCustoCadastro'));
const CentroCustoList = React.lazy(() => import('../../../pages/backoffice/centrocusto/CentroCustoList'));
const ParametroCadastro = React.lazy(() => import('../../../pages/Admin/parametro/ParametroCadastro'));
const ParametroList = React.lazy(() => import('../../../pages/Admin/parametro/ParametroList'));
const LimitadorCorridaCadastro = React.lazy(() =>
    import('../../../pages/ebooking/limitadorCorrida/LimitadorCorridaCadastro')
);
const LimitadorCorridaList = React.lazy(() => import('../../../pages/ebooking/limitadorCorrida/LimitadorCorridaList'));
const TurnosList = React.lazy(() => import('../../../pages/backoffice/turnos/TurnosList'));
const TurnosCadastro = React.lazy(() => import('../../../pages/backoffice/turnos/TurnosCadastro'));
const GrupoVeiculosList = React.lazy(() => import('../../../pages/backoffice/gruposveiculos/GrupoVeiculosList'));
const GrupoVeiculosCadastro = React.lazy(() =>
    import('../../../pages/backoffice/gruposveiculos/GrupoVeiculosCadastro')
);
const TipoOperacaoList = React.lazy(() => import('../../../pages/backoffice/tipoOperacao/TipoOperacaoList.js'));
const TipoOperacaoCadastro = React.lazy(() => import('../../../pages/backoffice/tipoOperacao/TipoOperadaoCadastro'));
const FeriadoCadastro = React.lazy(() => import('../../../pages/backoffice/feriado/FeriadoCadastro'));
const FeriadoList = React.lazy(() => import('../../../pages/backoffice/feriado/FeriadoList'));
const MotoristaAprovacao = React.lazy(() => import('../../../pages/motorista/MotoristaAprovacao'));

export const cadastroRoute = [
    { path: '/feriado', exact: true, roles: ['master', 'administrador'], component: FeriadoList, route: PrivateRoute },
    {
        path: '/feriado/adicionar',
        exact: true,
        roles: ['master', 'administrador'],
        component: FeriadoCadastro,
        route: PrivateRoute,
    },
    {
        path: '/feriado/atualizar/:id',
        exact: true,
        roles: ['master', 'administrador'],
        component: FeriadoCadastro,
        route: PrivateRoute,
    },
    {
        path: '/feriado/deletar/:id',
        exact: true,
        roles: ['master', 'administrador'],
        component: FeriadoCadastro,
        route: PrivateRoute,
    },

    {
        path: '/tipooperacao',
        exact: true,
        roles: ['master', 'administrador'],
        component: TipoOperacaoList,
        route: PrivateRoute,
    },
    {
        path: '/tipooperacao/adicionar',
        exact: true,
        roles: ['master', 'administrador'],
        component: TipoOperacaoCadastro,
        route: PrivateRoute,
    },
    {
        path: '/tipooperacao/atualizar/:id',
        exact: true,
        roles: ['master', 'administrador'],
        component: TipoOperacaoCadastro,
        route: PrivateRoute,
    },
    {
        path: '/tipooperacao/deletar/:id',
        exact: true,
        roles: ['master', 'administrador'],
        component: TipoOperacaoCadastro,
        route: PrivateRoute,
    },

    { path: '/turno', exact: true, roles: ['master', 'administrador'], component: TurnosList, route: PrivateRoute },
    {
        path: '/turno/adicionar',
        exact: true,
        roles: ['master', 'administrador'],
        component: TurnosCadastro,
        route: PrivateRoute,
    },
    {
        path: '/turno/atualizar/:id',
        exact: true,
        roles: ['master', 'administrador'],
        component: TurnosCadastro,
        route: PrivateRoute,
    },
    {
        path: '/turno/deletar/:id',
        exact: true,
        roles: ['master', 'administrador'],
        component: TurnosCadastro,
        route: PrivateRoute,
    },

    {
        path: '/grupoveiculo',
        exact: true,
        roles: ['master', 'administrador'],
        component: GrupoVeiculosList,
        route: PrivateRoute,
    },
    {
        path: '/grupoveiculo/adicionar',
        exact: true,
        roles: ['master', 'administrador'],
        component: GrupoVeiculosCadastro,
        route: PrivateRoute,
    },
    {
        path: '/grupoveiculo/atualizar/:id',
        exact: true,
        roles: ['master', 'administrador'],
        component: GrupoVeiculosCadastro,
        route: PrivateRoute,
    },
    {
        path: '/grupoveiculo/deletar/:id',
        exact: true,
        roles: ['master', 'administrador'],
        component: GrupoVeiculosCadastro,
        route: PrivateRoute,
    },

    {
        path: '/local',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral', 'gestorcliente', 'operadorcliente'],
        component: LocalList,
        route: PrivateRoute,
    },
    {
        path: '/local/adicionar',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral', 'gestorcliente', 'operadorcliente'],
        component: LocalCadastro,
        route: PrivateRoute,
    },
    {
        path: '/local/atualizar/:id',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral', 'gestorcliente', 'operadorcliente'],
        component: LocalCadastro,
        route: PrivateRoute,
    },
    {
        path: '/local/deletar/:id',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral', 'gestorcliente'],
        component: LocalCadastro,
        route: PrivateRoute,
    },

    {
        path: '/tarifa',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral', 'administrador'],
        component: TarifaList,
        route: PrivateRoute,
    },
    {
        path: '/tarifa/adicionar',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral', 'administrador'],
        component: TarifaCadastro,
        route: PrivateRoute,
    },
    {
        path: '/tarifa/atualizar/:id',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral', 'administrador'],
        component: TarifaCadastro,
        route: PrivateRoute,
    },
    {
        path: '/tarifa/deletar/:id',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral', 'administrador'],
        component: TarifaCadastro,
        route: PrivateRoute,
    },

    {
        path: '/admin/usuario/',
        exact: true,
        roles: ['master', 'administrador'],
        component: UsuarioList,
        route: PrivateRoute,
    },
    {
        path: '/admin/usuario/adicionar',
        exact: true,
        roles: ['master', 'administrador'],
        component: UsuarioCadastro,
        route: PrivateRoute,
    },
    {
        path: '/admin/usuario/atualizar/:id',
        exact: true,
        roles: ['master', 'administrador'],
        component: UsuarioCadastro,
        route: PrivateRoute,
    },
    {
        path: '/admin/usuario/deletar/:id',
        exact: true,
        roles: ['master', 'administrador'],
        component: UsuarioCadastro,
        route: PrivateRoute,
    },

    {
        path: '/admin/empresa/',
        exact: true,
        roles: ['master', 'administrador'],
        component: EmpresaList,
        route: PrivateRoute,
    },
    {
        path: '/admin/empresa/adicionar',
        exact: true,
        roles: ['master', 'administrador'],
        component: EmpresaCadastro,
        route: PrivateRoute,
    },
    {
        path: '/admin/empresa/atualizar/:id',
        exact: true,
        roles: ['master', 'administrador'],
        component: EmpresaCadastro,
        route: PrivateRoute,
    },
    {
        path: '/admin/empresa/deletar/:id',
        exact: true,
        roles: ['master', 'administrador'],
        component: EmpresaCadastro,
        route: PrivateRoute,
    },

    {
        path: '/admin/dominio',
        exact: true,
        roles: ['master', 'administrador'],
        component: DominioList,
        route: PrivateRoute,
    },
    {
        path: '/admin/dominio/adicionar',
        exact: true,
        roles: ['master', 'administrador'],
        component: DominioCadastro,
        route: PrivateRoute,
    },
    {
        path: '/admin/dominio/atualizar/:id',
        exact: true,
        roles: ['master', 'administrador'],
        component: DominioCadastro,
        route: PrivateRoute,
    },
    {
        path: '/admin/dominio/deletar/:id',
        exact: true,
        roles: ['master', 'administrador'],
        component: DominioCadastro,
        route: PrivateRoute,
    },

    {
        path: '/motorista',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral'],
        component: MotoristaList,
        route: PrivateRoute,
    },
    {
        path: '/motorista/adicionar',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral'],
        component: MotoristaCadastro,
        route: PrivateRoute,
    },
    {
        path: '/motorista/atualizar/:id',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral', 'aprovador'],
        component: MotoristaCadastro,
        route: PrivateRoute,
    },
    {
        path: '/motorista/deletar/:id',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral'],
        component: MotoristaCadastro,
        route: PrivateRoute,
    },

    {
        path: '/veiculo',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral'],
        component: VeiculoList,
        route: PrivateRoute,
    },
    {
        path: '/veiculo/adicionar',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral'],
        component: VeiculoCadastro,
        route: PrivateRoute,
    },
    {
        path: '/veiculo/atualizar/:id',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral'],
        component: VeiculoCadastro,
        route: PrivateRoute,
    },
    {
        path: '/veiculo/deletar/:id',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral'],
        component: VeiculoCadastro,
        route: PrivateRoute,
    },

    {
        path: '/passageiro',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral', 'operadorcliente', 'gestorcliente'],
        component: PassageiroList,
        route: PrivateRoute,
    },
    {
        path: '/passageiro/adicionar',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral', 'gestorcliente', 'operadorcliente'],
        component: PassageiroCadastro,
        route: PrivateRoute,
    },
    {
        path: '/passageiro/atualizar/:id',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral', 'gestorcliente', 'operadorcliente'],
        component: PassageiroCadastro,
        route: PrivateRoute,
    },
    {
        path: '/passageiro/deletar/:id',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral', 'gestorcliente'],
        component: PassageiroCadastro,
        route: PrivateRoute,
    },

    {
        path: '/centrocusto',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral', 'gestorcliente'],
        component: CentroCustoList,
        route: PrivateRoute,
    },
    {
        path: '/centrocusto/adicionar',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral', 'gestorcliente'],
        component: CentroCustoCadastro,
        route: PrivateRoute,
    },
    {
        path: '/centrocusto/atualizar/:id',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral', 'gestorcliente'],
        component: CentroCustoCadastro,
        route: PrivateRoute,
    },
    {
        path: '/centrocusto/deletar/:id',
        exact: true,
        roles: ['master', 'operadorcentral', 'gestorcentral', 'gestorcliente'],
        component: CentroCustoCadastro,
        route: PrivateRoute,
    },

    {
        path: '/admin/parametro',
        exact: true,
        roles: ['master', 'administrador'],
        component: ParametroList,
        route: PrivateRoute,
    },
    {
        path: '/admin/parametro/adicionar',
        exact: true,
        roles: ['master', 'administrador'],
        component: ParametroCadastro,
        route: PrivateRoute,
    },
    {
        path: '/admin/parametro/atualizar/:id',
        exact: true,
        roles: ['master', 'administrador'],
        component: ParametroCadastro,
        route: PrivateRoute,
    },
    {
        path: '/admin/parametro/deletar/:id',
        exact: true,
        roles: ['master', 'administrador'],
        component: ParametroCadastro,
        route: PrivateRoute,
    },

    {
        path: '/limitadorCorrida',
        exact: true,
        roles: ['master', 'administrador', 'gestorcliente'],
        component: LimitadorCorridaList,
        route: PrivateRoute,
    },
    {
        path: '/limitadorCorrida/adicionar',
        exact: true,
        roles: ['master', 'administrador', 'gestorcliente'],
        component: LimitadorCorridaCadastro,
        route: PrivateRoute,
    },
    {
        path: '/limitadorCorrida/atualizar/:id',
        exact: true,
        roles: ['master', 'administrador', 'gestorcliente'],
        component: LimitadorCorridaCadastro,
        route: PrivateRoute,
    },
    {
        path: '/limitadorCorrida/deletar/:id',
        exact: true,
        roles: ['master', 'administrador', 'gestorcliente'],
        component: LimitadorCorridaCadastro,
        route: PrivateRoute,
    },
    {
        path: '/motorista/aprovacoes',
        exact: true,
        roles: ['master', 'gestorcentral', 'aprovador'],
        component: MotoristaAprovacao,
        route: PrivateRoute,
    },
];
